import axios from 'axios';
import {getToken, clearUserCookie} from "../utils/local-storage";
import {Notification} from 'element-ui';
import {ServiceError} from "../enum/service-error";

const request = axios.create({
    baseURL: "/api",
    withCredentials: false,
    timeout: 10 * 1000,
    headers: {
        "content-type": 'application/json; charset=utf-8'
    }
});
request.interceptors.request.use((config) => {
    let token = getToken();
    if (null !== token){
        config.headers['Authorization'] = 'Bearer ' + token
    }
    return config;
}, error => {
    return Promise.reject(error);
})

request.interceptors.response.use(
    response => {
        if (response.data) {
            if (response.data['code'] === 200) {
                return Promise.resolve(response);
            }
            if (response.data['code'] === 200400) {
                window.location.reload()
                clearUserCookie();
            }
            let message = ServiceError[response.data['code']];
            Notification.warning({
                title: '警告',
                message: message ? message : response.data['message'],
            })
            return Promise.reject(response)
        }
        return Promise.resolve(response);
    },
    (error) => {
        console.log("请求异常：" + error)
        if (error['response']?.status === 404) {
            Notification.error({
                title: '错误',
                message: '服务器访问异常请联系开发人员'
            })
        } else {
            if (error['code'] === "ECONNABORTED" && error.message.includes("timeout")) {
                Notification.error({
                    title: '错误',
                    message: '服务器请求超时'
                })
            }
            else {
                Notification.error({
                    title: '错误',
                    message: '服务器内部异常请联系开发人员'
                })
            }
        }
        return Promise.reject(error)
    }
)

export default request